import React, { useEffect, useState, useRef } from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar'
// react plugin for creating notifications over the dashboard
import NotificationAlert from 'react-notification-alert'

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js'
import Footer from 'components/Footer/Footer.js'
import Sidebar from 'components/Sidebar/Sidebar.js'

import routes from 'routes.js'

import logo from 'assets/img/autron-logo.png'

import useAlert from 'services/hooks/useAlert'
import { filterByStatuses } from 'services/Utils'

var ps

const Admin = ({ props, user }) => {
  const [activeColor] = useState('primary')
  const [, setSidebarMini] = useState(false)
  const [opacity, setOpacity] = useState(0)
  const [sidebarOpened, setSidebarOpened] = useState(false)
  const mainPanel = useRef()
  const { addAlert } = useAlert()
  const location = useLocation()

  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    if (mainPanel.current) {
      mainPanel.current.scrollTop = 0
    }
  }, [location])

  useEffect(() => {
    let innerMainPanelRef = mainPanel
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.classList.add('perfect-scrollbar-on')
      document.documentElement.classList.remove('perfect-scrollbar-off')
      ps = new PerfectScrollbar(mainPanel.current)
      mainPanel.current &&
        mainPanel.current.addEventListener('ps-scroll-y', showNavbarButton)
      let tables = document.querySelectorAll('.table-responsive')
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i])
      }
    }
    window.addEventListener('scroll', showNavbarButton)
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy()
        document.documentElement.classList.add('perfect-scrollbar-off')
        document.documentElement.classList.remove('perfect-scrollbar-on')
        innerMainPanelRef.current &&
          innerMainPanelRef.current.removeEventListener(
            'ps-scroll-y',
            showNavbarButton
          )
      }
      window.removeEventListener('scroll', showNavbarButton)
    }
  }, [])

  const showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50 ||
      -mainPanel.current.getBoundingClientRect().top > 50
    ) {
      setOpacity(1)
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50 ||
      -mainPanel.current.getBoundingClientRect().top <= 50
    ) {
      setOpacity(0)
    }
  }

  const getRoutes = (routes, user) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views, user)
      }

      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component user={user} />}
            key={key}
          />
        )
      }

      return null
    })
  }

  const getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text'
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views)
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name
        }
      }
    }
    return activeRoute
  }
  const handleMiniClick = () => {
    let notifyMessage = 'Sidebar mini '
    if (document.body.classList.contains('sidebar-mini')) {
      setSidebarMini(false)
      notifyMessage += 'deactivated...'
    } else {
      setSidebarMini(true)
      notifyMessage += 'activated...'
    }
    addAlert('primary', 'tim-icons icon-bell-55', notifyMessage, null)
    document.body.classList.toggle('sidebar-mini')
  }
  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened)
    document.documentElement.classList.toggle('nav-open')
  }
  const closeSidebar = () => {
    setSidebarOpened(false)
    document.documentElement.classList.remove('nav-open')
  }

  const handleOnboardingRedirect = (user, pathname) => {
    const redirectPathname = '/admin/onboarding'
    const paymentPath = '/admin/account/billing'

    if (!pathname.includes(redirectPathname)) {
      const { stripe_customer_id, stripe_subscription_items } = user.company

      const isSubscribed =
        stripe_customer_id !== null &&
        filterByStatuses(stripe_subscription_items, ['active', 'trialing']) !==
          null

      const isAdmin = user.admin === 'True' ? true : false

      if (
        [...user.company.sellers, ...user.company.managed_sellers]?.length > 0
      ) {
        if (!isAdmin) {
          if (!isSubscribed) {
            if (pathname !== paymentPath) {
              return <Redirect from={pathname} to={paymentPath} />
            }
          }
        }

        let primarySeller = user.primary_seller
        let onlyManagedSellers =
          user.company.sellers?.length === 0 &&
          user.company.managed_sellers?.length > 0
            ? true
            : false
        if (primarySeller?.profiles?.length > 0) {
          if (primarySeller.ingest_completed_at) {
            if (
              primarySeller?.company.managing_company?.public_id &&
              primarySeller?.company.managing_company?.public_id !==
                user.company.public_id
            ) {
              // redirect to onboarding managed by external company
              if (pathname !== redirectPathname) {
                return <Redirect from={pathname} to={redirectPathname} />
              }
            }
          } else {
            // redirect to onboarding data syncing
            if (pathname !== redirectPathname) {
              return <Redirect from={pathname} to={redirectPathname} />
            }
          }
        } else {
          if (onlyManagedSellers) {
            if (pathname !== redirectPathname) {
              return <Redirect from={pathname} to={redirectPathname} />
            }
          } else {
            const sellerPath =
              '/admin/onboarding/seller/' +
              (primarySeller.public_id ? primarySeller.public_id : '')
            if (pathname !== sellerPath) {
              return <Redirect from={pathname} to={sellerPath} />
            }
          }
        }
      } else {
        const { public_id } = user.company.managing_company
        if (public_id) {
          if (pathname !== redirectPathname) {
            return <Redirect from={pathname} to={redirectPathname} />
          }
        } else {
          if (!isAdmin) {
            if (!isSubscribed) {
              if (pathname !== paymentPath) {
                return <Redirect from={pathname} to={paymentPath} />
              }
            } else {
              if (pathname !== redirectPathname) {
                return <Redirect from={pathname} to={redirectPathname} />
              }
            }
          }
        }
      }
    }

    return null
  }

  // Filter routes for sidebar based on user conditions
  const sidebarRoutes = routes.filter((route) => {
    return true
  })

  return (
    <div className="admin wrapper">
      <div className="rna-container">
        <NotificationAlert />
      </div>
      <div className="navbar-minimize-fixed" style={{ opacity: opacity }}>
        <button
          className="minimize-sidebar btn btn-link btn-just-icon"
          onClick={handleMiniClick}
        >
          <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
          <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
        </button>
      </div>
      <Sidebar
        {...props}
        routes={sidebarRoutes}
        activeColor={activeColor}
        logo={{
          outterLink: '/admin/dashboard',
          text: 'Autron',
          imgSrc: logo,
        }}
        closeSidebar={closeSidebar}
      />
      <div className="main-panel" ref={mainPanel} data={activeColor}>
        <AdminNavbar
          {...props}
          handleMiniClick={handleMiniClick}
          brandText={getActiveRoute(routes)}
          sidebarOpened={sidebarOpened}
          toggleSidebar={toggleSidebar}
          user={user}
        />
        <Switch>
          {handleOnboardingRedirect(user, props.location.pathname)}
          {getRoutes(routes, user)}
          <Redirect from="*" to="/admin/dashboard" />
        </Switch>
        {
          // we don't want the Footer to be rendered on full screen maps page
          props.location.pathname.indexOf('full-screen-map') !== -1 ? null : (
            <Footer fluid />
          )
        }
      </div>
    </div>
  )
}

export default Admin
