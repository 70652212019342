import React, { useState, Fragment } from 'react'
import classNames from 'classnames'
// reactstrap components
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  Button,
} from 'reactstrap'
import { useHistory, useParams, Link } from 'react-router-dom'
import InsightsDashboard from './InsightsDashboard'
import { freeUser } from 'services/Utils'

const Insights = ({ user }) => {
  const getCustomDashboardId = (user) => {
    // Custom dashboard IDs based on user.company.public_id
    const customDashboardIds = {
      // Add custom dashboard IDs here in the format: 'public_id': 'dashboard_id'
      '0462b7b8-7ac5-4bac-9b80-313792ed50b7': 17, // SimpleIda Dev
      'c84a371b-6f1f-4e88-86ac-0458572e1f8d': 17, // Element 26
    }

    return customDashboardIds[user.company.public_id] || null
  }
  const insights = [
    {
      name: 'Sales & Traffic',
      slug: 'sales-and-traffic',
      description:
        'The Sales & Traffic dashboard presents a comprehensive snapshot of sales metrics, user behavior patterns, and product popularity over time. With in-depth category insights and platform usage data, it offers a clear perspective on the pulse and trajectory of your business operations.',
      description2: '',
      dashboardId: 2,
    },
    {
      name: 'Profitability',
      slug: 'profitability',
      description:
        "The Profitability dashboard displays financial metrics, category-specific profitability, and product earnings. With insights by category and ASIN, it provides clarity on your business's financial health. Use this data for strategic planning, optimizing costs, and enhancing profitability.",
      description2:
        "Note: For precise profit calculations, add a 'Unit Cost' to your <a href='/admin/products'>products</a>. It might take 24 hours for updates to reflect on the dashboard. Profit metrics are accurate when filtering by Category, Parent ASIN, or ASIN only if Autron manages your entire advertising.",
      dashboardId: 3,
    },
    {
      name: 'Advertising',
      slug: 'advertising',
      description:
        "The Advertising dashboard delivers insights on ad performance, showcasing metrics like Ad Sales, Spend, and ACOS. With data segmented by campaign types, products, and categories, it's your guide to better advertising decisions. Compare Autron-managed versus Non-Autron campaigns to fine-tune your advertising strategies effectively.",
      description2:
        "Note: The Category, Parent ASIN, and ASIN filters are accurate solely for advertising data under Autron's management. It is recommended to use these filters after Autron has overseen your advertising account for several months to obtain precise advertising insights.",
      dashboardId: 8,
    },
    {
      name: 'Search Terms',
      slug: 'search-terms',
      description:
        'The Search Term dashboard unveils the impact of specific search terms on sales. It lays out essential metrics like Impressions, Ad Spend, Attributed Sales, ACOS, and Conversion Rate. Navigate through the top-contributing terms to refine listing keyword strategies. Perfect for capturing consumer search behavior and adjusting to market trends.',
      description2: '',
      dashboardId: 4,
    },
    {
      name: 'Customers',
      slug: 'customers',
      description:
        'The Customer dashboard offers a comprehensive view of your customer trends, differentiating between new and returning customers over selected periods. Explore geographic insights, analyze cohort-based lifetime value, and understand purchase behaviors. With metrics such as CAC, Lifetime Value, and Breakeven ACOS, this dashboard equips you to refine customer retention efforts and optimize sales strategies.',
      description2:
        "Note: For accurate LTV and Breakeven ACOS, add a 'Unit Cost' to your <a href='/admin/products'>products</a>. It might take 24 hours for updates to reflect on the dashboard. Cohort metrics are accurate when filtering by Category, Parent ASIN, or ASIN only if Autron manages your entire advertising.",
      dashboardId: 7,
    },
    {
      name: 'Benchmarks',
      slug: 'benchmarks',
      description:
        'The Benchmarks dashboard delivers essential insights into how your brand stacks up throughout the entire customer journey on Amazon, compared to your competition. With unique weekly competitive indexes, you can track meaningful progress. Plus, Sponsored Brands campaign benchmarks spotlight your advertising performance against competitors in your key categories.',
      description2: '',
      dashboardId: 16,
    },
    {
      name: 'Custom',
      slug: 'custom',
      description:
        'The Custom dashboard is crafted to meet your specific needs, providing in-depth insights, data visualizations, and comprehensive reports.',
      description2: '',
      dashboardId: getCustomDashboardId(user),
    },
  ]

  const params = useParams()
  const history = useHistory()

  const [horizontalTabs, setHorizontalTabs] = useState(
    params.type ? params.type : insights[0].slug
  )

  return (
    <>
      <div className="content">
        <Row>
          <Col
            className={classNames({
              'd-flex justify-content-center align-items-end mt-xl': false,
            })}
          >
            {false ? (
              <Spinner /> // used for initial load
            ) : (
              <Fragment>
                <Card className="card-plain info-area">
                  <CardHeader>
                    <CardTitle tag="h2">Insights</CardTitle>
                    <hr className="line-primary mb-2" />
                  </CardHeader>
                  <CardBody>
                    <p className="card-description description">
                      Comprehensive analytics tool to strategize your Amazon
                      business with data-driven insights.
                    </p>
                  </CardBody>
                </Card>
                <Card className="">
                  <CardBody>
                    <Nav className="nav-tabs-info" tabs>
                      {insights.map((insight) => (
                        <NavItem key={insight.slug}>
                          <NavLink
                            data-toggle="tab"
                            href="#"
                            className={
                              horizontalTabs === insight.slug ? 'active' : ''
                            }
                            onClick={(e) => {
                              setHorizontalTabs(insight.slug)
                              history.push('/admin/insights/' + insight.slug)
                            }}
                          >
                            {insight.name}
                            {['benchmarks', 'custom'].includes(insight.slug) &&
                              horizontalTabs !== insight.slug && (
                                <span className="badge-insights-new">New</span>
                              )}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                    <TabContent
                      className="tab-space"
                      activeTab={horizontalTabs}
                    >
                      {insights.map((insight) => (
                        <TabPane tabId={insight.slug} key={insight.slug}>
                          {freeUser(user) ? (
                            <Fragment>
                              <CardTitle tag="h4">{insight.name}</CardTitle>
                              <p className="card-description mb-2">
                                Upgrade to Autron Pro and unlock in-depth
                                analytics dashboards to drive your Amazon
                                business strategy with data insights.
                              </p>
                              <Button
                                className="btn-round btn-just-icon mt-4"
                                color="primary"
                                tag={Link}
                                to="/admin/account/billing"
                              >
                                Upgrade to Pro
                              </Button>
                            </Fragment>
                          ) : (
                            <InsightsDashboard
                              insight={insight}
                              seller={user.primary_seller}
                            />
                          )}
                        </TabPane>
                      ))}
                    </TabContent>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>
              </Fragment>
            )}
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Insights
