import React, { useState, useMemo, useEffect, Fragment, useRef } from 'react'
import classNames from 'classnames'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Button,
  Spinner,
  Row,
  Col,
} from 'reactstrap'
import { useQuery } from 'react-query'
import useAlert from 'services/hooks/useAlert'
import { useHistory, useLocation, Link } from 'react-router-dom'
import ReactTable from 'components/ReactTable/ReactTable.js'
import apiStripe from 'services/api/Stripe'
import { DateTime } from 'luxon'
import { sortByField } from 'services/Utils'
import {
  segmentCheckoutStarted,
  segmentCheckoutCancelled,
} from 'services/Segment'
import * as Sentry from '@sentry/react'
import useStripe from 'services/hooks/useStripe'
import useStripeSetup from 'services/hooks/useStripeSetup'
import Payment from '../payment/Payment'

const Billing = ({ user }) => {
  const params = new URLSearchParams(useLocation().search)
  const [sessionId] = useState(params.get('session_id'))
  const [canceled] = useState(params.get('canceled'))
  const history = useHistory()
  const { addAlert } = useAlert()
  const { setup, isLoading } = useStripeSetup()
  const stripe = useStripe()

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState('')
  const [session, setSession] = useState({})

  const [prices, setPrices] = useState([])
  const [features, setFeatures] = useState([])

  const [stripeCustomerId, setStripeCustomerId] = useState(
    user?.company?.stripe_customer_id
  )
  const [invoices, setInvoices] = useState([])

  const [stripeClientReferenceId, setStripeClientReferenceId] = useState('') // used by rewardful

  const [userUpgradePrice, setUserUpgradePrice] = useState({})
  const successCardEndRef = useRef(null)

  useEffect(() => {
    if (setup) {
      setPrices(setup.prices?.sort(sortByField('unit_amount')))
      setFeatures(setup.features)
      setUserUpgradePrice(getUserUpgradePrice(user?.company, setup.prices))
    }
  }, [setup, user.company])

  // Get Stripe publishable key (and prices/products) to initialize Stripe.js
  const { data: invs } = useQuery(
    'GET/stripe/customer-upcoming-invoices',
    () => apiStripe.getCustomerUpcomingInvoices(stripeCustomerId),
    {
      // staleTime: 1440 * 60 * 1000, // 60 minutes
      enabled: !!stripeCustomerId, // The query will not execute until the primarySellerId exists
      onSuccess: (invs) => {
        setInvoices(invs?.data.invoices)
      },
    }
  )

  const getUserUpgradePrice = (company, ps) => {
    var subs = company.stripe_subscription_items
    if (ps) {
      const sub = subs.find((sub) =>
        ['active', 'trialing', 'past_due'].includes(sub.subscription_status)
      )
      var upgradePrice
      if (sub) {
        const upgradeProductName = prices?.find(
          (price) => price.id === sub.price_id
        )?.product_metadata.upgrade_product_name
        if (upgradeProductName) {
          upgradePrice = ps.find(
            (price) =>
              price.product_name === upgradeProductName &&
              price.recurring_interval === sub.price_recurring_interval
          )
        }
      } else {
        if (
          [
            '07617a50-e0c9-45f2-bf8e-396918ef3d74', // Profitable Pineapple Ads
            '3c01a454-d498-407e-83f5-0fe1646bd9ae', // Click Convert
          ].includes(company.public_id)
        ) {
          upgradePrice = ps
            .sort(sortByField('unit_amount'))
            .find((price) => price.product_name === 'Custom')
        } else {
          // default to first metered pay monthly product in price stack
          upgradePrice = ps
            .sort(sortByField('unit_amount'))
            .find(
              (price) =>
                price.recurring_usage_type === 'metered' &&
                price.recurring_interval === 'month'
            )
        }
      }
      return upgradePrice
    }
    return null
  }

  const pricingCardBtnClick = async (stripe, e) => {
    const priceId = e.target.value
    if (!priceId) {
      e.preventDefault()
      return
    }

    try {
      setLoading(true)
      const usageType = prices?.find(
        (price) => price.id === priceId
      )?.recurring_usage_type
      const data = {
        price_id: priceId,
        usage_type: usageType || '',
        customer_id: user.company.stripe_customer_id,
        customer_email: user.email,
        user_public_id: user.public_id,
        company_public_id: user.company.public_id,
        client_reference_id: stripeClientReferenceId,
      }

      const response = await apiStripe.createCheckoutSession(data)

      if (response?.data.session) {
        segmentCheckoutStarted(user, data)
        // Redirect to Stripe Checkout
        const result = await stripe.redirectToCheckout({
          sessionId: response.data.session.id,
        })
        if (result.error) {
          throw new Error(result.error.message)
        }
      } else {
        throw new Error(
          response.data.message || 'Failed to create checkout session.'
        )
      }
    } catch (error) {
      Sentry.captureException(error)
      addAlert('danger', 'tim-icons icon-alert-circle-exc', error.message)
    } finally {
      setLoading(false)
    }
  }

  const manageClick = async (e) => {
    setLoading(true)
    await apiStripe
      .createCustomerPortal()
      .then(function (response) {
        return response
      })
      .then(function (response) {
        if (response?.data.session_url) {
          window.location.href = response.data.session_url
        } else {
          throw new Error(response.data.message)
        }
      })
      .catch(function (error) {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          error.message,
          null
        )
      })
    setLoading(false)
  }

  const scrollToSuccess = () => {
    return successCardEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToSuccess()
  }, [session])

  const onSuccess = async (sessionId) => {
    setLoading(true)
    await apiStripe
      .getCheckoutSession(sessionId)
      .then(function (response) {
        return response
      })
      .then(function (response) {
        if (response?.data.session) {
          setSession(response.data.session)
        } else {
          throw new Error(response.data.message)
        }
      })
      .catch(function (error) {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          error.message,
          null
        )
      })
    setLoading(false)
  }

  useEffect(() => {
    if (sessionId) {
      onSuccess(sessionId)
    }
    if (canceled) {
      segmentCheckoutCancelled(user)
      addAlert(
        'danger',
        'tim-icons icon-alert-circle-exc',
        'Checkout canceled :(',
        null
      )
    }
  }, [sessionId, canceled]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const p = history.location.pathname.split('/').pop()
    setPage(p)
  }, [history.location.pathname])

  useEffect(() => {
    const id =
      (window.Rewardful && window.Rewardful.referral) ||
      'checkout_' + user?.public_id + '_' + new Date().getTime()
    setStripeClientReferenceId(id)
  }, [user])

  const [siMerged, setSiMerged] = useState([])

  useEffect(() => {
    const subscriptionItemMerger = (stripeSubscriptionItems) => {
      const mergedSubscriptions = stripeSubscriptionItems.reduce(
        (acc, item) => {
          const key = item.subscription

          if (!acc[key]) {
            acc[key] = { ...item, licensed_price_unit_amount: null }
          }

          if (item.price_billing_scheme === 'tiered') {
            acc[key] = { ...acc[key], ...item }
          }

          if (
            item.price_billing_scheme === 'per_unit' &&
            item.price_recurring_usage_type === 'licensed'
          ) {
            acc[key].licensed_price_unit_amount = item.price_unit_amount
          }

          return acc
        },
        {}
      )

      setSiMerged(Object.values(mergedSubscriptions))
    }

    subscriptionItemMerger(user.company.stripe_subscription_items)
  }, [user.company.stripe_subscription_items])

  const subscriptionColumns = useMemo(
    () => [
      {
        Header: 'Plan',
        accessor: 'price_product_name',
        sortable: false,
        Cell: (props) => <strong>{props.value}</strong>,
      },
      {
        Header: 'Status',
        accessor: 'subscription_status',
        sortable: false,
        Cell: (props) => (
          <span
            className={classNames(
              'status_cell',
              ['past_due'].includes(props.value) ||
                (props.value === 'active' &&
                  props.row.original['subscription_cancel_at_period_end'] &&
                  props.row.original['subscription_cancel_at'])
                ? 'status-warning'
                : ['active', 'trialing'].includes(props.value)
                ? 'status-success'
                : 'status-danger'
            )}
          >
            {(props.value === 'active' || props.value === 'trialing') &&
            props.row.original['subscription_cancel_at_period_end'] &&
            props.row.original['subscription_cancel_at']
              ? 'Cancels ' +
                new DateTime.fromISO(
                  props.row.original['subscription_cancel_at']
                )
                  .setLocale('en-US')
                  .toLocaleString(DateTime.DATE_MED)
              : props.value}
          </span>
        ),
      },
      {
        Header: 'Price',
        accessor: 'price_unit_amount',
        sortable: false,
        Cell: (props) => priceCell(props),
      },
      {
        Header: 'Next Invoice',
        accessor: 'subscription_current_period_end',
        sortable: false,
        Cell: (props) =>
          ['active', 'trialing'].includes(
            props.row.original['subscription_status']
          ) && !props.row.original['subscription_cancel_at_period_end']
            ? new DateTime.fromISO(props.value)
                .setLocale('en-US')
                .toLocaleString(DateTime.DATE_MED)
            : null,
      },
      {
        Header: 'Next Invoice Amount Due',
        accessor: 'price_recurring_usage_type',
        sortable: false,
        Cell: (props) =>
          ['active', 'trialing'].includes(
            props.row.original['subscription_status']
          ) && !props.row.original['subscription_cancel_at_period_end']
            ? invoices?.length > 0
              ? Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: props.row.original['price_currency'],
                  minimumFractionDigits: 2,
                }).format(
                  invoices.find(
                    (i) =>
                      i.subscription_id === props.row.original['subscription']
                  )?.amount_due / 100
                )
              : ''
            : null,
      },
    ],
    [prices, invoices]
  )

  const priceCell = (props) => {
    let price = 0
    if (props.row.original['price_billing_scheme'] === 'per_unit') {
      price = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: props.row.original['price_currency'],
        minimumFractionDigits: 0,
      }).format(props.value / 100)
    } else {
      // metered
      const p = prices?.find(
        (price) => price.id === props.row.original['price_id']
      )
      if (!p) return 'Custom' // handles custom Pro prices e.g. CPR
      price =
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: props.row.original['price_currency'],
          minimumFractionDigits: 0,
        }).format(props.row.original['licensed_price_unit_amount'] / 100) +
        (p?.tiers.length > 0 ? ' + % of Ad Sales' : '')
    }

    return (
      <Fragment>
        <span>
          {price +
            ' / ' +
            (props.row.original['price_recurring_interval_count'] === 1
              ? ''
              : ' ' +
                props.row.original['price_recurring_interval_count'] +
                ' ') +
            props.row.original['price_recurring_interval']}
        </span>
      </Fragment>
    )
  }

  return (
    <>
      <div
        className={classNames({
          'd-flex justify-content-center align-items-end mt-xl': isLoading,
        })}
      >
        {isLoading ? (
          <Spinner /> // used for initial load
        ) : (
          {
            billing: userUpgradePrice ? (
              <Fragment>
                {userUpgradePrice.product_name === 'Custom' ? (
                  <Fragment>
                    <Row>
                      <Col className="ml-auto mr-auto text-center" md="8">
                        <h2 className="title">Become an Agency Partner</h2>
                        <p className="description mb-5">
                          Grow your agency with Autron's AI decision engine and
                          automated advertising software. Spend less time
                          managing campaigns and more time driving client
                          success.
                        </p>
                        <Button
                          className={classNames(
                            'btn-round btn-just-icon btn-wd',
                            { disabled: loading || !stripe }
                          )}
                          color="primary"
                          value={userUpgradePrice.product_id} // pass product_id here instead of price_id since required for ad hoc price_data
                          onClick={(e) => pricingCardBtnClick(stripe, e)}
                        >
                          {loading === true ? (
                            <Spinner size="sm" role="status"></Spinner>
                          ) : (
                            'Upgrade to Agency Partner'
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Fragment>
                ) : (
                  <Payment />
                )}
              </Fragment>
            ) : (
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Billing</CardTitle>
                </CardHeader>
                <CardBody>
                  {user.company.stripe_subscription_items ? (
                    <Fragment>
                      <ReactTable
                        columns={subscriptionColumns}
                        data={siMerged}
                        loading={isLoading}
                        total={user.company.stripe_subscription_items.length}
                      />
                    </Fragment>
                  ) : null}
                </CardBody>
                <CardFooter>
                  {user.company.stripe_subscription_items ? (
                    <Fragment>
                      <Button
                        color="primary"
                        className={classNames('btn-wd', { disabled: loading })}
                        onClick={(e) => manageClick(e)}
                      >
                        {loading === true ? (
                          <Spinner size="sm" role="status"></Spinner>
                        ) : (
                          'Manage Billing'
                        )}
                      </Button>
                    </Fragment>
                  ) : null}
                </CardFooter>
              </Card>
            ),
            success: (
              <Fragment>
                <Card className="text-center">
                  <CardHeader>
                    <CardTitle tag="h3"></CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="success-checkmark">
                      <div className="check-icon">
                        <span className="icon-line line-tip"></span>
                        <span className="icon-line line-long"></span>
                        <div className="icon-circle"></div>
                        <div className="icon-fix"></div>
                      </div>
                    </div>
                    <h1 className="title">Thank you, {user.firstname}!</h1>
                    <p className="card-description description mb-5">
                      You are now subscribed to Autron{' '}
                      <strong>
                        {session?.line_items?.data[0].price.product.name}
                      </strong>{' '}
                      plan. <br /> We're excited to help you take your business
                      to the next level.
                    </p>
                    {/* <pre className="json text-left">{JSON.stringify(session, null, 2)}</pre> */}
                    <Button
                      color="primary"
                      className={classNames('btn-wd')}
                      tag={Link}
                      to="/admin/dashboard"
                    >
                      Back to dashboard
                    </Button>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>
                <div ref={successCardEndRef} />
              </Fragment>
            ),
            canceled: 'canceled', // not used, do it from billing with url param
          }[page]
        )}
      </div>
    </>
  )
}

export default Billing
