import React, { Fragment, useState, useEffect } from 'react'
import classNames from 'classnames'
// reactstrap components
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Label,
  Spinner,
} from 'reactstrap'

import Switch from 'react-bootstrap-switch'

import IframeResizer from 'iframe-resizer-react'
import { useQuery, useQueryClient } from 'react-query'
import apiAnalytics from 'services/api/Analytics'
import { useHistory } from 'react-router-dom'
import useAlert from 'services/hooks/useAlert'
import apiReviewAutomation from 'services/api/ReviewAutomation'
import SkeletonInsights from 'components/SkeletonCard/SkeletonInsights'

const Review = ({ user }) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { addAlert } = useAlert()

  const [loading, setLoading] = useState(true)
  const [loadingSwitch, setLoadingSwitch] = useState(true)
  const [reviewState, setReviewState] = useState(false)

  const toogleReviewState = async () => {
    const marketplace_id = user.primary_seller.primary_marketplace_id
    const selling_partner_id = user.primary_seller.selling_partner_id

    const profileId = user.primary_seller.profiles.find(
      (item) =>
        item.account_info_marketplace_string_id === marketplace_id &&
        item.account_info_id === selling_partner_id
    )?.profile_id

    const payload = {
      seller_selling_partner_id: selling_partner_id,
      marketplace_marketplace_id: marketplace_id,
      profile_profile_id: profileId,
      state: reviewState ? 'disabled' : 'enabled',
    }

    setLoadingSwitch(true)
    const response = await apiReviewAutomation.setState(payload, true)
    try {
      const response = await apiReviewAutomation.getState(payload)

      if (response && response.status === 200) {
        setReviewState(payload.state === 'disabled' ? false : true)
        setLoadingSwitch(false)
      } else {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          response ? response.data.message : 'Failed to process request.',
          null
        )
      }
    } catch (error) {
      addAlert(
        'danger',
        'tim-icons icon-alert-circle-exc',
        'Failed to process request due to an error.',
        null
      )
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const marketplace_id = user.primary_seller.primary_marketplace_id
      const selling_partner_id = user.primary_seller.selling_partner_id

      const profileId = user.primary_seller.profiles.find(
        (item) =>
          item.account_info_marketplace_string_id === marketplace_id &&
          item.account_info_id === selling_partner_id
      )?.profile_id

      const payload = {
        seller_selling_partner_id: selling_partner_id,
        marketplace_marketplace_id: marketplace_id,
        profile_profile_id: profileId,
      }

      try {
        const response = await apiReviewAutomation.getState(payload)

        if (response && response.status === 200) {
          setReviewState(response.data.state === 'enabled')
          setLoadingSwitch(false)
        } else {
          addAlert(
            'danger',
            'tim-icons icon-alert-circle-exc',
            response ? response.data.message : 'Failed to process request.',
            null
          )
        }
      } catch (error) {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          'Failed to process request due to an error.',
          null
        )
      }
    }

    fetchData()
  }, [reviewState])

  // for dashboard
  const analyticsUrl = process.env.REACT_APP_FRONTEND_APP_BASE_URL
  const [iframeUrl, setIframeUrl] = useState(analyticsUrl)
  const embedUrlParam = {
    resource: { dashboard: 14 },
    params: {
      seller_id: user.primary_seller.selling_partner_id,
      marketplace_id: user.primary_seller.primary_marketplace_id,
    },
  }

  // Get Metabase iframeUrl
  const { data: embedUrl, isFetching: embedUrlIsLoading } = useQuery(
    ['POST/analytics/embed', embedUrlParam],
    () => apiAnalytics.generateEmbedUrl(embedUrlParam),
    {
      staleTime: 10 * 60 * 1000, // 10 minutes since iframe_url is only valid for 11 minutes
      onSuccess: (embedUrl) => {
        if (embedUrl && embedUrl.status < 300) {
          setIframeUrl(embedUrl.data.iframe_url)
        } else {
          // could handle this better but for now just assume token expired
          history.go(0) // refresh page
        }
      },
    }
  )

  useEffect(() => {
    const iframeUrlCache = queryClient.getQueryData([
      'POST/analytics/embed',
      embedUrlParam,
    ])

    if (iframeUrlCache && iframeUrlCache.data?.iframe_url) {
      setIframeUrl(iframeUrlCache.data.iframe_url)
    }
  }, [queryClient, embedUrlParam])

  return (
    <>
      <div className="content">
        <Row
          className={classNames({
            'd-flex justify-content-center align-items-end': loading,
          })}
        >
          <Fragment>
            <Col xs="12">
              <Card className="card-plain info-area">
                <CardHeader>
                  <CardTitle tag="h2">Review Automation</CardTitle>
                  <hr className="line-primary mb-2" />
                </CardHeader>
                <CardBody>
                  <p className="card-description description">
                    {
                      "Automate product review requests following Amazon's terms of service. "
                    }
                    <a
                      href={
                        'https://help.autron.ai/en/articles/9910134-automate-amazon-product-review-requests'
                      }
                      target="_blank"
                    >
                      Learn more.
                    </a>
                  </p>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Row className="mx-0 mt-2 mb-4">
                    <Col className="review-switch-col">
                      <p className="card-description mb-1">
                        Boost your product reviews with an Amazon-approved
                        solution. Stay compliant with Amazon’s terms of service
                        while effortlessly increasing your reviews. Set it and
                        forget it, eliminating the hassle of manually requesting
                        reviews on Seller Central.
                      </p>
                      <p className="card-description mb-4 font-italic">
                        Note: Amazon allows review requests only between 5 and
                        30 days after the delivery date, so expect a lag between
                        orders and review requests.
                      </p>
                      {loadingSwitch ? (
                        <span className="review-spinner-container">
                          <Spinner size="sm" role="status" />
                        </span>
                      ) : (
                        <Switch
                          value={reviewState}
                          offColor=""
                          onColor=""
                          onChange={() => toogleReviewState()}
                        />
                      )}

                      <Label className="ml-3 mb-0">Review Automation</Label>
                    </Col>
                  </Row>

                  <Row>
                    {reviewState && iframeUrl !== analyticsUrl && (
                      <Fragment>
                        {loading && <SkeletonInsights />}
                        <IframeResizer
                          src={iframeUrl}
                          style={{
                            width: '1px',
                            minWidth: '100%',
                            minHeight: '62.5em',
                          }}
                          hidden={loading}
                          onLoad={() => setLoading(false)}
                        />
                      </Fragment>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Fragment>
        </Row>
      </div>
    </>
  )
}

export default Review
