import React, { useState, Fragment } from 'react'
import { useHistory, Link } from 'react-router-dom'
import apiAuth from 'services/api/Auth.js'
import { useQuery } from 'react-query'
import classNames from 'classnames'
import { copyTextToClipboard, managedSellerInviteLink } from 'services/Utils'

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Col,
  NavLink,
  Spinner,
} from 'reactstrap'
import syncingImg from 'assets/img/syncing-bg.png'
import cardPrimaryImg from 'assets/img/card-primary.png'

const Onboarding = () => {
  const [primarySeller, setPrimarySeller] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()

  const { data: user } = useQuery('GET/auth/user', () => apiAuth.user(), {
    onSuccess: (user) => {
      setPrimarySeller(user.primary_seller)
      setIsLoading(false)
    },
  })

  const connectAccountClick = async (public_id = null) => {
    if (public_id) {
      history.push('/admin/onboarding/seller/' + public_id)
    } else {
      history.push('/admin/onboarding/seller/')
    }
  }

  return (
    <div className="content onboarding">
      <Col
        className={classNames({
          'd-flex justify-content-center align-items-end mt-xl': isLoading,
          'ml-auto mr-auto col-md-6 col-lg-4 mt-5 pt-5': !isLoading,
        })}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <Fragment>
            <Card className="card-pricing card-primary">
              {primarySeller?.profiles?.length > 0 ? (
                primarySeller?.ingest_completed_at ? (
                  primarySeller?.company.managing_company?.public_id &&
                  primarySeller?.company.managing_company?.public_id !==
                    user.company.public_id ? (
                    <Fragment>
                      <CardBody className="text-center mb-5">
                        <ListGroup className="mt-5">
                          <ListGroupItem>
                            {user?.firstname}, this account is managed by{' '}
                            <span className="font-weight-bold text-underline">
                              {primarySeller?.company.managing_company?.name}
                            </span>
                            , if you would like to change this{' '}
                            <NavLink
                              className="d-inline p-0 div-link"
                              tag={Link}
                              to={{
                                pathname: `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=Seller: ${primarySeller.public_id} - External Account Management`,
                              }}
                              target="_blank"
                            >
                              Contact Us.
                            </NavLink>
                          </ListGroupItem>
                          <ListGroupItem>
                            Or, click below to add/edit Amazon accounts under{' '}
                            {primarySeller?.company.managing_company?.name}{' '}
                            managment.
                          </ListGroupItem>
                        </ListGroup>
                        <Button
                          className="btn-wd btn-just-icon mb-3"
                          color="primary"
                          onClick={(e) => connectAccountClick()}
                        >
                          Add New Amazon Account
                        </Button>
                        <Button
                          className="btn-just-icon btn-simple"
                          color="primary"
                          onClick={(e) =>
                            connectAccountClick(primarySeller.public_id)
                          }
                        >
                          Edit {primarySeller?.company.name} Connection
                        </Button>
                      </CardBody>
                    </Fragment>
                  ) : null
                ) : (
                  <Fragment>
                    <CardBody>
                      <CardTitle tag="h1" className="syncing-card">
                        syncing
                      </CardTitle>
                      <CardImg
                        alt="..."
                        src={syncingImg}
                        className="syncing-card-image"
                      />
                      <ListGroup className="mt-5">
                        <ListGroupItem>
                          {user?.firstname}, we're syncing your Amazon account.
                        </ListGroupItem>
                        <ListGroupItem>
                          This can <strong>take up to 24 hours</strong>, we'll
                          email you once complete.
                        </ListGroupItem>
                      </ListGroup>
                    </CardBody>
                    <CardFooter className="text-center mb-3 mt-0">
                      <NavLink
                        className="d-inline p-0"
                        tag={Link}
                        to={{
                          pathname: `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=Seller: ${primarySeller.public_id} - account sync not complete after 24 hours`,
                        }}
                        target="_blank"
                      >
                        Didn't recieve an email?
                      </NavLink>
                    </CardFooter>
                  </Fragment>
                )
              ) : (
                <Fragment>
                  <CardBody>
                    <CardTitle tag="h1" className="hello-card">
                      hello
                    </CardTitle>
                    <CardImg
                      alt="..."
                      src={cardPrimaryImg}
                      className="hello-card-image"
                    />
                    <ListGroup className="mt-5">
                      <ListGroupItem className="mt-5">
                        Welcome {user?.firstname}!
                      </ListGroupItem>
                      <ListGroupItem>
                        Get started by connecting an Amazon account
                      </ListGroupItem>
                    </ListGroup>
                  </CardBody>
                  <CardFooter className="text-center mb-3 mt-0">
                    <Button
                      className="btn-round btn-just-icon"
                      color="primary"
                      onClick={(e) => connectAccountClick()}
                    >
                      Connect Amazon Account
                    </Button>
                  </CardFooter>
                </Fragment>
              )}
            </Card>

            {!primarySeller?.profiles?.length > 0 ? (
              <div className="text-center">
                Looking to manage someone else's Amazon Ad account?
                <br />
                <span
                  className="d-inline p-0 div-link"
                  onClick={(e) => {
                    copyTextToClipboard(
                      managedSellerInviteLink(
                        user?.company?.public_id,
                        user?.company?.name
                      )
                    )
                  }}
                >
                  Copy Invite Link
                </span>
              </div>
            ) : null}
          </Fragment>
        )}
      </Col>
    </div>
  )
}

export default Onboarding
