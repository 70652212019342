import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Alert } from 'reactstrap'
import apiAuth from 'services/api/Auth'
import apiStripe from 'services/api/Stripe'

const AlertCountdown = () => {
  const [daysLeft, setDaysLeft] = useState(null)
  const [subscriptionItem, setSubscriptionItem] = useState(null)

  useQuery('GET/auth/user', () => apiAuth.user(), {
    retry: false,
    onSuccess: (user) => {
      if (user.company.stripe_customer_id) {
        getSubscriptionItem(user)
      } else {
        setSubscriptionItem(null)
      }
    },
  })

  const getSubscriptionItem = async (user) => {
    while (!subscriptionItem) {
      try {
        const { data } = await apiStripe.getSubscriptionItem(
          user.company.stripe_customer_id
        )
        if (data) {
          setSubscriptionItem(data)
          return
        }
      } catch (error) {
        setDaysLeft(null)
        setSubscriptionItem(null)
      }
      // Wait for four seconds before retrying
      await new Promise((resolve) => setTimeout(resolve, 4000))
    }
  }

  useEffect(() => {
    if (!subscriptionItem) {
      setDaysLeft(null)
      return
    }

    if (subscriptionItem.subscription_status === 'trialing') {
      const currentDate = new Date()

      const subscriptionTrialEnd = new Date(
        subscriptionItem.subscription_trial_end
      )

      // Compute the time difference in milliseconds
      const timeDifference =
        subscriptionTrialEnd.getTime() - currentDate.getTime()

      // Compute the difference in days
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))

      setDaysLeft(daysDifference)
    }
  }, [subscriptionItem])

  return (
    <>
      {daysLeft >= 0 && daysLeft != null && (
        <div className="w-100 m-0">
          <Alert className="d-flex justify-content-center m-0" color="danger">
            <p className="trial-alert-text">
              Your trial ends in{' '}
              <strong>
                {daysLeft} {daysLeft === 1 ? 'day' : 'days'}
              </strong>
            </p>
          </Alert>
        </div>
      )}
    </>
  )
}

export default AlertCountdown
