import React, { useEffect, useState, useRef, Fragment, useContext } from 'react'
import classNames from 'classnames'
import Slider from 'nouislider'
import Select from 'react-select'
import Switch from 'react-bootstrap-switch'
import ReactBSAlert from 'react-bootstrap-sweetalert'
// reactstrap components
import {
  Button,
  Spinner,
  UncontrolledTooltip,
  Row,
  Col,
  InputGroup,
  Input,
  Label,
  FormGroup,
  Form,
  CardTitle,
  NavLink,
} from 'reactstrap'
import { inputFieldChange } from 'services/Utils'
import useAlert from 'services/hooks/useAlert'
import apiGoals from 'services/api/Goal'
import { useQueryClient } from 'react-query'
import { useAsyncDebounce } from 'react-table'
import { useHistory, Link } from 'react-router-dom'
import ThemeContext from 'services/providers/ThemeContext'

const Goal = ({
  objectives,
  strategies,
  type,
  goal,
  goalGlobalPublicId,
  goalGlobalASIN,
  products,
  newProducts,
  seller,
  profileId,
  isFreeUser,
  dataIsLoading,
}) => {
  const { addAlert } = useAlert()
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()
  const history = useHistory()

  const [loadingDisable, setloadingDisable] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const { lightMode } = useContext(ThemeContext)

  const types = [
    { value: goalGlobalPublicId, label: 'Global' },
    { value: '', label: 'Custom' },
  ]

  const ACOSPlaceholder = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(0.3)
  const TACOSPlaceholder = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(0.15)
  const minACOS = 5
  const minTACOS = 1
  const minBudget = 10
  const recommendedPriority = 60
  const [currency, setCurrency] = useState('USD')
  const [budgetPlaceholder, setBudgetPlaceholder] = useState()

  const [publicId, setPublicId] = useState('')

  const [goalASIN, setGoalASIN] = useState(
    goalGlobalASIN ? { value: goalGlobalASIN, label: goalGlobalASIN } : {}
  )
  const [goalASINValidation, setGoalASINValidation] = useState(
    goalGlobalASIN ? 'has-success' : ''
  )
  const [goalASINFocus, setGoalASINFocus] = useState('')
  const [goalASINOptions, setGoalASINOptions] = useState([])

  const [objective, setObjective] = useState()
  const [objectiveValidation, setObjectiveValidation] = useState('')
  const [objectiveFocus, setObjectiveFocus] = useState('')
  const [objectiveOptions] = useState(objectives)

  const [strategy, setStrategy] = useState()
  const [strategyValidation, setStrategyValidation] = useState('')
  const [strategyFocus, setStrategyFocus] = useState('')
  const [strategyOptions, setStrategyOptions] = useState(strategies)

  const [profitTarget, setProfitTarget] = useState()

  const [targetACOS, setTargetACOS] = useState('')
  const [targetACOSValidation, setTargetACOSValidation] = useState('')
  const [targetROAS, setTargetROAS] = useState(0)

  const [targetTACOS, setTargetTACOS] = useState('')
  const [targetTACOSValidation, setTargetTACOSValidation] = useState('')
  const [targetTROAS, setTargetTROAS] = useState(0)

  const [targetBudget, setTargetBudget] = useState('')
  const [targetBudgetValidation, setTargetBudgetValidation] = useState('')

  const [adsOn, setAdsOn] = useState(false)
  const [turboOn, setTurboOn] = useState(false)

  const [goalType, setGoalType] = useState()
  const [goalTypeOptions] = useState(types)

  const [priorityValidation, setPriorityValidation] = useState('')
  const [negativeProfitValidation, setNegativeProfitValidation] =
    useState(false)
  const prioritySliderRef = useRef(null)
  const [avgProductProfit, setAvgProductProfit] = useState(0)
  const [avgProductMargin, setAvgProductMargin] = useState(0)
  const [avgProductAdSpend, setAvgProductAdSpend] = useState(0)
  const [avgProductAdSpendPerc, setAvgProductAdSpendPerc] = useState(0)

  const campaignTypeOptions = [
    { value: 'all', label: 'All' },
    { value: 'sp_targets', label: 'Product Targeting' },
  ]

  const useCampaignTypes =
    publicId &&
    type !== 'global' &&
    goalType?.label === 'Custom' &&
    [
      'd49bc0d2-5ecd-43af-9ddd-ffe6ea7f0301',
      'ccda45de-9d76-4df6-bbfe-426c8f134739', // SI
      '9d3ed2f5-3af7-430e-af18-5b0ab08e4198', // SmashCommerce
    ].includes(seller.company.public_id)

  const [campaignTypes, setCampaignTypes] = useState(
    goal?.campaign_type
      ?.split(',')
      ?.map((t) => campaignTypeOptions.find((o) => o.value === t)) || [
      campaignTypeOptions[0],
    ]
  )

  const [showCustomFields, setShowCustomFields] = useState(false)
  const [showMaxProfitFields, setShowMaxProfitFields] = useState(false)
  const [showMaxSalesFields, setShowMaxSalesFields] = useState(false)
  const [showACOSFields, setShowACOSFields] = useState(false)
  const [showProfitFields, setShowProfitFields] = useState(false)
  const [showBudgetFields, setShowBudgetFields] = useState(false)
  const [showTACOSFields, setShowTACOSFields] = useState(false)

  const [turnOffAdsWarningAlert, setTurnOffAdsWarningAlert] = useState(null)

  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    if (seller?.primary_marketplace_currency_code) {
      const cur = seller.primary_marketplace_currency_code
      setBudgetPlaceholder(
        Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: cur ? cur : 'USD',
        }).format(100)
      )
      setCurrency(cur)
    }
  }, [seller])

  useEffect(() => {
    if (products?.length) {
      setGoalASINOptions(
        products?.map((p) => ({ value: p.asin, label: p.asin }))
      )
    } else if (newProducts?.length) {
      setGoalASINOptions(
        newProducts?.map((asin) => ({ value: asin, label: asin }))
      )
    }
  }, [products, newProducts])

  useEffect(() => {
    const id = goal?.public_id
    const obj = objectives.find((o) => o.value === goal?.objective)
    const strat = strategies.find((s) => s.value === goal?.strategy)
    const strat_val = goal?.strategy_value
    const state = goal ? (goal.state === 'enabled' ? true : false) : false
    const turbo_mode = goal
      ? goal.turbo_mode === 'enabled'
        ? true
        : false
      : false
    const goal_type = types.find(
      (t) =>
        t.value ===
        (goal?.use_global_public_id ? goal?.use_global_public_id : '')
    )
    setPublicId(id)
    setObjective(obj)
    setGoalType(goal_type)
    setShowCustomFields(goal_type?.value ? false : true)
    switch (obj?.value) {
      case 'maximize_sales':
        setObjectiveValidation('has-success')
        setStrategy(strat)
        setShowMaxSalesFields(true)
        switch (strat?.value) {
          case 'minimum_net_profit':
            setStrategyValidation('has-success')
            //setPriorityValidation('has-success')
            setProfitTarget(strat_val * 100)
            setShowProfitFields(true)
            break
          case 'maximum_acos':
            setStrategyValidation('has-success')
            setTargetACOSValidation('has-success')
            setTargetACOS(strat_val * 100)
            setShowACOSFields(true)
            break
          case 'maximum_daily_budget':
            setStrategyValidation('has-success')
            setTargetBudgetValidation('has-success')
            setTargetBudget(strat_val)
            setShowBudgetFields(true)
            break
          case 'maximum_tacos':
            setStrategyValidation('has-success')
            setTargetTACOSValidation('has-success')
            setTargetTACOS(strat_val * 100)
            setShowTACOSFields(true)
            break
          default:
          // code block
        }
        break
      case 'maximize_profit':
        setObjectiveValidation('has-success')
        break
      default:
      // code block
    }
    setAdsOn(state)
    setTurboOn(turbo_mode)
  }, [goal])

  const resetFieldInputs = () => {
    setTargetACOSValidation('')
    setTargetBudgetValidation('')
    setTargetTACOSValidation('')

    setTargetACOS('')
    setTargetBudget('')
    setProfitTarget()
    setTargetTACOS('')
    if (prioritySliderRef.current && prioritySliderRef.current.noUiSlider) {
      prioritySliderRef.current.noUiSlider.set(recommendedPriority)
    }

    updateROAS('')
    updateTROAS('')
  }

  const goalTypeSelect = (goalType) => {
    setGoalType(goalType)
    setShowCustomFields(goalType?.value ? false : true)
    if (goalType?.value) {
      setAdsOn(true)
    }
  }

  const objectiveSelect = (objective) => {
    setStrategy('')
    setStrategyValidation('')
    setObjectiveValidation('has-success')
    if (objective !== 'maximize_profit') {
      setShowMaxProfitFields(false)
      setShowMaxSalesFields(true)
    } else {
      setShowMaxProfitFields(true)
      setShowMaxSalesFields(false)
      setShowACOSFields(false)
      setShowProfitFields(false)
      setShowBudgetFields(false)
      setShowTACOSFields(false)
      resetFieldInputs()
    }
  }

  const strategySelect = (strategy) => {
    setStrategyValidation('has-success')
    setObjectiveValidation('has-success')
    resetFieldInputs()
    switch (strategy) {
      case 'minimum_net_profit':
        setShowProfitFields(true)
        setShowACOSFields(false)
        setShowBudgetFields(false)
        setShowTACOSFields(false)
        break
      case 'maximum_acos':
        setShowACOSFields(true)
        setShowProfitFields(false)
        setShowBudgetFields(false)
        setShowTACOSFields(false)
        break
      case 'maximum_daily_budget':
        setShowBudgetFields(true)
        setShowACOSFields(false)
        setShowProfitFields(false)
        setShowTACOSFields(false)
        break
      case 'maximum_tacos':
        setShowACOSFields(false)
        setShowProfitFields(false)
        setShowBudgetFields(false)
        setShowTACOSFields(true)
        break
      default:
      // code block
    }
  }

  const updateROAS = (acos) => {
    setTargetROAS(1 / (Number(acos) / 100))
  }

  const updateTROAS = (tacos) => {
    setTargetTROAS(1 / (Number(tacos) / 100))
  }

  const debounceSetProductStats = useAsyncDebounce(
    (ad_spend, ad_spend_perc, net_profit, net_profit_margin) => {
      setAvgProductAdSpend(ad_spend)
      setAvgProductAdSpendPerc(ad_spend_perc)
      setAvgProductProfit(net_profit)
      setAvgProductMargin(net_profit_margin)
    },
    10
  )

  const updateProductStats = (
    values,
    handle,
    unencoded,
    tap,
    positions,
    noUiSlider
  ) => {
    // values: Current slider values (array);
    // handle: Handle that caused the event (number);
    // unencoded: Slider values without formatting (array);
    // tap: Event was caused by the user tapping the slider (boolean);
    // positions: Left offset of the handles (array);
    // noUiSlider: slider public Api (noUiSlider);
    let priorityValue = unencoded[0] / 100
    const data = products?.map((product, key) => {
      let price = product.price || product.your_price
      const out = {
        asin: product.asin,
        price: price,
        profit: product.profit,
        ad_spend: product.profit * priorityValue,
        ad_spend_perc: (product.profit * priorityValue) / price,
        net_profit: product.profit * (1 - priorityValue),
        net_profit_margin: (product.profit * (1 - priorityValue)) / price,
      }
      return out
    })
    const stats = {
      mean_profit:
        data?.reduce((prev, cur) => prev + cur.profit, 0) / data?.length,
      mean_ad_spend:
        data?.reduce((prev, cur) => prev + cur.ad_spend, 0) / data?.length,
      mean_ad_spend_perc:
        data?.reduce((prev, cur) => prev + cur.ad_spend_perc, 0) / data?.length,
      mean_net_profit:
        data?.reduce((prev, cur) => prev + cur.net_profit, 0) / data?.length,
      mean_net_profit_margin:
        data?.reduce((prev, cur) => prev + cur.net_profit_margin, 0) /
        data?.length,
    }

    debounceSetProductStats(
      // for smoother sliding...
      stats.mean_ad_spend,
      stats.mean_ad_spend_perc,
      stats.mean_net_profit,
      stats.mean_net_profit_margin
    )

    if (priorityValue < 0.15) {
      setPriorityValidation('has-danger')
    } else {
      setPriorityValidation('')
    }
    if (stats.mean_net_profit < 0) {
      setNegativeProfitValidation(true)
    } else {
      setNegativeProfitValidation(false)
    }
  }

  const meanNetProfitMarginToSliderValue = (xbar, prods) => {
    // see notes for expression, xbar is meanNetProfitMargin as %
    // x = 1 - (xbar / c) where c = sum(profit / numProducts * price)
    const c = prods?.reduce(
      (prev, cur) =>
        prev + cur.profit / (prods.length * (cur.price || cur.your_price)),
      0
    )
    return (1 - xbar / 100 / c) * 100
  }

  const recommendedClickTargetACOS = (e) => {
    setObjective(objectives[0])
    setObjectiveValidation('has-success')

    setStrategy(strategies[0])
    setStrategyOptions(strategies)
    setStrategyValidation('has-success')

    setShowMaxProfitFields(false)
    setShowACOSFields(false)
    setShowTACOSFields(false)
    setShowBudgetFields(false)
    setShowProfitFields(true)
    setShowMaxSalesFields(true)

    setAdsOn(true)

    if (prioritySliderRef.current && prioritySliderRef.current.noUiSlider) {
      prioritySliderRef.current.noUiSlider.set(recommendedPriority)
    }
  }

  const recommendedClick = (e) => {
    const rObj = 'maximize_sales'
    const rStrat = 'maximum_acos' // 'minimum_net_profit'

    let bACOSFields = false
    let bProfitFields = false
    let bTACOSFields = false
    if (rStrat === 'maximum_acos') {
      bACOSFields = true
      bProfitFields = false
      bTACOSFields = false
    } else if (rStrat === 'maximum_tacos') {
      bACOSFields = false
      bProfitFields = false
      bTACOSFields = true
    } else {
      bACOSFields = false
      bProfitFields = true
      bTACOSFields = false
    }

    setObjective(objectives.find((o) => o.value === rObj))
    setObjectiveValidation('has-success')

    setStrategy(strategies.find((s) => s.value === rStrat))
    setStrategyOptions(strategies)
    setStrategyValidation('has-success')

    setShowMaxProfitFields(false)
    setShowACOSFields(bACOSFields)
    setShowBudgetFields(false)
    setShowProfitFields(bProfitFields)
    setShowMaxSalesFields(true)
    setShowTACOSFields(bTACOSFields)

    setTurboOn(false)

    if (bACOSFields) {
      setTargetACOS(30)
      setTargetACOSValidation('has-success')
    } else {
      if (prioritySliderRef.current && prioritySliderRef.current.noUiSlider) {
        prioritySliderRef.current.noUiSlider.set(recommendedPriority)
      }
    }
  }

  const disableGoalState = async (e) => {
    if (type !== 'global') {
      setloadingDisable(true)
      e.preventDefault()
    }

    const goal_campaign_type = goal?.campaign_type
      ?.split(',')
      ?.map((t) => campaignTypeOptions.find((o) => o.value === t)) || [
      campaignTypeOptions[0],
    ]

    const goalData = {
      objective: goal.objective,
      strategy: goal.strategy,
      strategy_value: goal.strategy_value,
      type: goal.type,
      state: 'disabled',
      turbo_mode: turboOn ? 'enabled' : 'disabled',
      seller_selling_partner_id: seller.selling_partner_id,
      marketplace_marketplace_id: seller.primary_marketplace_id,
      profile_profile_id: profileId,
      use_global_public_id: goal.use_global_public_id,
      ...(type === 'global' ? { product_asin: goalASIN.value } : {}),
      ...(goal?.campaign_type && { campaign_type: goal_campaign_type }),
    }

    if (goal?.isBulk) {
      goalData.public_ids = products?.map((item) => item.goal_public_id) || []
    }

    try {
      let response
      if (goal?.isBulk) {
        response = await apiGoals.updateMultiple(goalData)
      } else if (publicId) {
        response = await apiGoals.updateSingle(publicId, goalData)
      }

      if (response && response.status < 300) {
        queryClient.invalidateQueries('GET/sellers/')
        addAlert(
          'success',
          'tim-icons icon-check-2',
          response.data.message,
          null
        )
        history.push('/admin/goals')
      } else {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          response ? response.data.message : 'Failed to process request.',
          null
        )
      }
    } catch (error) {
      addAlert(
        'danger',
        'tim-icons icon-alert-circle-exc',
        error?.message,
        null
      )
    } finally {
      if (type !== 'global') {
        setloadingDisable(false)
      }
      // remove modal if it exist
      setModalLoading(false)
      setShowAlert(false)
    }
  }

  const toogleTurboOn = () => {
    if (type === 'global' && !turboOn === false) {
      setTurboOn(!turboOn)
    } else {
      setTurboOn(!turboOn)
    }
  }

  const saveClick = async (e) => {
    const isGoalValid = () => {
      if (!isFreeUser || goalASINValidation === 'has-success') {
        if (objectiveValidation !== 'has-success') return false

        if (objective.value !== 'maximize_profit') {
          if (strategyValidation !== 'has-success') return false

          switch (strategy.value) {
            case 'minimum_net_profit':
              return true
            case 'maximum_acos':
              return targetACOSValidation === 'has-success'
            case 'maximum_daily_budget':
              return targetBudgetValidation === 'has-success'
            case 'maximum_tacos':
              return targetTACOSValidation === 'has-success'
            default:
              return false
          }
        }

        return true
      }

      return false
    }

    if (isGoalValid()) {
      setLoading(true)

      const goalData = {
        objective: objective.value,
        strategy: strategy?.value || null,
        strategy_value:
          strategy?.value === 'minimum_net_profit'
            ? avgProductMargin
            : strategy?.value === 'maximum_acos'
            ? targetACOS / 100
            : strategy?.value === 'maximum_daily_budget'
            ? targetBudget
            : strategy?.value === 'maximum_tacos'
            ? targetTACOS / 100
            : null,
        type: type,
        state: 'enabled',
        turbo_mode: turboOn ? 'enabled' : 'disabled',
        seller_selling_partner_id: seller.selling_partner_id,
        marketplace_marketplace_id: seller.primary_marketplace_id,
        profile_profile_id: profileId,
        use_global_public_id: goalType.value || null,
        ...(type === 'global' && { product_asin: goalASIN.value }),
        ...(useCampaignTypes && { campaign_type: campaignTypes[0].value }),
      }

      if (goal?.isBulk) {
        goalData.public_ids = products?.map((item) => item.goal_public_id) || []
      }

      try {
        let response
        if (goal?.isBulk) {
          response = await apiGoals.updateMultiple(goalData)
        } else if (publicId) {
          response = await apiGoals.updateSingle(publicId, goalData)
        } else {
          response = await apiGoals.createSingle(goalData)
        }

        if (response && response.status < 300) {
          queryClient.invalidateQueries('GET/sellers/')
          addAlert(
            'success',
            'tim-icons icon-check-2',
            response.data.message,
            null
          )
          history.push('/admin/goals')
        } else {
          addAlert(
            'danger',
            'tim-icons icon-alert-circle-exc',
            response ? response.data.message : 'Failed to process request.',
            null
          )
        }
      } catch (error) {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          error?.message,
          null
        )
      } finally {
        setLoading(false)
      }
    } else {
      if (isFreeUser && goalASINValidation === '') {
        setGoalASINValidation('has-danger')
      }
      if (objectiveValidation === '') {
        setObjectiveValidation('has-danger')
      }
      if (strategyValidation === '') {
        setStrategyValidation('has-danger')
      }
      if (targetACOSValidation === '') {
        setTargetACOSValidation('has-danger')
      }
      if (targetBudgetValidation === '') {
        setTargetBudgetValidation('has-danger')
      }
      if (targetTACOSValidation === '') {
        setTargetTACOSValidation('has-danger')
      }
      e.preventDefault()
    }
  }

  useEffect(() => {
    updateROAS(targetACOS)
  }, [targetACOS])

  useEffect(() => {
    updateTROAS(targetTACOS)
  }, [targetTACOS])

  const updatePips = (value, type) => {
    switch (true) {
      case value >= 0 && value < 50:
        value = 'Profit'
        break
      case value >= 50 && value < 150:
        value = 'Sales'
        break
      case value >= 150:
        value = 'Growth'
        break
      default:
        break
    }
    return value
  }

  useEffect(() => {
    if (prioritySliderRef.current !== null) {
      Slider.create(prioritySliderRef.current, {
        range: { min: 0, '75%': 100, max: 200 },
        start: [recommendedPriority],
        connect: [true, false],
        step: 0.1,
        pips: {
          mode: 'positions',
          values: [0, 75, 100],
          density: 100,
          format: { to: updatePips },
        },
        tooltips: [false],
        format: {
          // 'to' the formatted value. Receives a number.
          to: function (value) {
            return Intl.NumberFormat('en-US', {
              style: 'percent',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(value / 100)
          },
          // 'from' the formatted value.
          // Receives a string, should return a number.
          from: function (value) {
            return value
          },
        },
      })
    }
  }, [])
  useEffect(() => {
    if (prioritySliderRef.current !== null) {
      prioritySliderRef.current.noUiSlider.on('update', updateProductStats)
    }
  }, [products])

  useEffect(() => {
    if (prioritySliderRef.current !== null) {
      prioritySliderRef.current.noUiSlider.set(
        meanNetProfitMarginToSliderValue(profitTarget, products)
      )
    }
  }, [profitTarget, products])

  const handleModalConfirm = () => {
    setModalLoading(true)
    disableGoalState()
  }

  const handleModalCancel = () => {
    setShowAlert(false)
  }

  const showAlertModal = () => {
    setShowAlert(true)
  }

  return (
    <>
      {/* {turnOffAdsWarningAlert} */}

      {showAlert && (
        <ReactBSAlert
          show={showAlert}
          warning
          showCancel
          title={'Are you sure?'}
          confirmBtnText={
            modalLoading ? (
              <Spinner size="sm" role="status" />
            ) : (
              'Yes, I want to go it alone!'
            )
          }
          cancelBtnText="Cancel"
          onConfirm={handleModalConfirm}
          onCancel={handleModalCancel}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          customButtons={
            <Fragment>
              <Button
                color="danger"
                variant="danger"
                onClick={handleModalCancel}
              >
                Cancel
              </Button>
              <Button
                color="success"
                variant="success"
                onClick={handleModalConfirm}
                disabled={modalLoading}
              >
                {modalLoading ? (
                  <Spinner size="sm" role="status" />
                ) : (
                  'Yes, I want to go it alone!'
                )}
              </Button>
            </Fragment>
          }
        >
          {
            'This is the kill switch for Autron! All AI automations will halt and ads under Autron management will be removed.'
          }
        </ReactBSAlert>
      )}

      {dataIsLoading ? (
        <Row>
          <Col
            className={classNames({
              'd-flex justify-content-center align-items-end mt-xl mb-xl':
                dataIsLoading,
            })}
          >
            <Spinner />
          </Col>
        </Row>
      ) : (
        <Fragment>
          <Row className="mb-3">
            <Col sm="8">
              {type === 'global' ? (
                <Fragment>
                  <CardTitle tag="h4">Global Goal</CardTitle>
                  {isFreeUser ? (
                    <p className="card-description mb-2">
                      You can assign 1 product to your global goal.{' '}
                      <NavLink
                        className="d-inline p-0 div-link"
                        tag={Link}
                        to="/admin/account/billing"
                      >
                        Upgrade to add additional products and custom goals.
                      </NavLink>
                    </p>
                  ) : (
                    <p className="card-description mb-2">
                      Your global goal affects{' '}
                      <strong
                        className={`${lightMode ? 'text-muted' : 'text-white'}`}
                      >
                        all
                      </strong>{' '}
                      Ads managed by Autron, unless a custom product goal is
                      set.
                    </p>
                  )}
                </Fragment>
              ) : (
                <CardTitle tag="h4">
                  <span className="truncate">
                    Product Goal:{' '}
                    {products?.length > 0
                      ? products?.length > 1
                        ? products.map((item) => item.asin).join(', ')
                        : products[0]['asin']
                      : ''}
                  </span>
                </CardTitle>
              )}
            </Col>
          </Row>
          <Form className="form-horizontal">
            {type !== 'global' ? (
              <Row>
                <Label sm="3" className="">
                  Goal Used
                  <Button
                    color="link"
                    id="goals-goal-type-label-tooltip"
                    title=""
                    type="button"
                    className={`p-0 m-0 mt-n1 pl-2 ${
                      lightMode ? 'text-muted' : ''
                    }`}
                  >
                    <i className="fas fa-question-circle"></i>
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    target="goals-goal-type-label-tooltip"
                  >
                    Create a custom goal for this product or set to use global
                    goal settings.
                  </UncontrolledTooltip>
                </Label>
                <Col sm="9" md="6">
                  <InputGroup>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      name="goalType"
                      value={goalType}
                      placeholder=""
                      onChange={(e) => goalTypeSelect(e)}
                      options={goalTypeOptions}
                    />
                  </InputGroup>
                </Col>
              </Row>
            ) : (
              <Fragment>
                {isFreeUser ? (
                  <Row className={classNames('mb-3')}>
                    <Label sm="3" className="">
                      Assign Product ASIN
                      <Button
                        color="link"
                        id="goals-asin-label-tooltip"
                        title=""
                        type="button"
                        className={`p-0 m-0 mt-n1 pl-2 ${
                          lightMode ? 'text-muted' : ''
                        }`}
                      />
                    </Label>
                    <Col sm="9" md="6">
                      <InputGroup
                        className={classNames(goalASINValidation, {
                          'input-group-focus': goalASINFocus,
                        })}
                      >
                        <Select
                          className={classNames('react-select info', {
                            'input-group-focus': goalGlobalASIN ? true : false,
                          })}
                          isDisabled={goalGlobalASIN ? true : false}
                          classNamePrefix="react-select"
                          name="objective"
                          value={goalASIN}
                          placeholder=""
                          onChange={(e) => {
                            inputFieldChange(
                              e,
                              setGoalASIN,
                              setGoalASINValidation,
                              'select_length',
                              1
                            )
                            objectiveSelect(e.value)
                          }}
                          onFocus={(e) => setGoalASINFocus(true)}
                          onBlur={(e) => setGoalASINFocus(false)}
                          options={goalASINOptions}
                        />
                        {goalASINValidation === 'has-danger' ? (
                          <label className="error">
                            This field is required.
                          </label>
                        ) : null}
                      </InputGroup>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Label sm="3"></Label>
                  <Col sm="9" md="6">
                    <FormGroup className="pb-1">
                      <p
                        className="form-control-static div-link"
                        onClick={(e) => recommendedClick(e)}
                      >
                        Apply recommended settings
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
              </Fragment>
            )}
            <Row className={classNames({ 'd-none': !showCustomFields })}>
              <Label sm="3" className="">
                Objective
                <Button
                  color="link"
                  id="goals-objective-label-tooltip"
                  title=""
                  type="button"
                  className={`p-0 m-0 mt-n1 pl-2 ${
                    lightMode ? 'text-muted' : ''
                  }`}
                >
                  <i className="fas fa-question-circle"></i>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="goals-objective-label-tooltip"
                >
                  Autron's Ad Engine optimizes performance towards your chosen
                  objective
                </UncontrolledTooltip>
              </Label>
              <Col sm="9" md="6">
                <InputGroup
                  className={classNames(objectiveValidation, {
                    'input-group-focus': objectiveFocus,
                  })}
                >
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    name="objective"
                    value={objective}
                    placeholder=""
                    onChange={(e) => {
                      inputFieldChange(
                        e,
                        setObjective,
                        setObjectiveValidation,
                        'select_length',
                        1
                      )
                      objectiveSelect(e.value)
                    }}
                    onFocus={(e) => setObjectiveFocus(true)}
                    onBlur={(e) => setObjectiveFocus(false)}
                    options={objectiveOptions}
                  />
                  {objectiveValidation === 'has-danger' ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
              </Col>
            </Row>

            <Row
              className={classNames({
                'd-none': !(showCustomFields && showMaxSalesFields),
              })}
            >
              <Label sm="3" className="">
                Strategy
                <Button
                  color="link"
                  id="goals-strategy-label-tooltip"
                  title=""
                  type="button"
                  className={`p-0 m-0 mt-n1 pl-2 ${
                    lightMode ? 'text-muted' : ''
                  }`}
                >
                  <i className="fas fa-question-circle"></i>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="goals-strategy-label-tooltip"
                >
                  Choose a strategy that meets your operational or budgetary
                  constraints
                </UncontrolledTooltip>
              </Label>
              <Col sm="9" md="6">
                <InputGroup
                  className={classNames(strategyValidation, {
                    'input-group-focus': strategyFocus,
                  })}
                >
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    name="strategy"
                    value={strategy}
                    placeholder=""
                    onChange={(e) => {
                      inputFieldChange(
                        e,
                        setStrategy,
                        setStrategyValidation,
                        'select_length',
                        objective.value === 'maximize_profit' ? 0 : 1
                      )
                      strategySelect(e.value)
                    }}
                    onFocus={(e) => setStrategyFocus(true)}
                    onBlur={(e) => setStrategyFocus(false)}
                    options={strategyOptions}
                  />
                  {strategyValidation === 'has-danger' ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
              </Col>
            </Row>

            <Row
              className={classNames({
                'd-none': !(
                  showCustomFields &&
                  showMaxSalesFields &&
                  showACOSFields
                ),
              })}
            >
              <Label sm="3" className="">
                {lightMode ? (
                  <b>Target ACOS %</b>
                ) : (
                  <b className="text-white">Target ACOS %</b>
                )}
                <Button
                  color="link"
                  id="goals-acos-label-tooltip"
                  title=""
                  type="button"
                  className={`p-0 m-0 mt-n1 pl-2 ${
                    lightMode ? 'text-muted' : ''
                  }`}
                >
                  <i className="fas fa-question-circle"></i>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="goals-acos-label-tooltip"
                >
                  Set your Advertising Cost of Sale (ACOS) target, defined as
                  the percent of attributed sales spent on advertising
                </UncontrolledTooltip>
              </Label>
              <Col sm="9" md="6">
                <FormGroup className={targetACOSValidation}>
                  <Input
                    name="shipping_cost"
                    type="number"
                    min={minACOS}
                    max="1000"
                    step="0.01"
                    value={targetACOS}
                    placeholder={ACOSPlaceholder}
                    onChange={(e) => {
                      inputFieldChange(
                        e,
                        setTargetACOS,
                        setTargetACOSValidation,
                        'positiveNumberGTE',
                        minACOS
                      )
                    }}
                  />
                  {targetACOSValidation === 'has-danger' ? (
                    <label className="error">
                      This field is required and must be greater than {minACOS}
                    </label>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>

            <Row
              className={classNames({
                'd-none': !(
                  showCustomFields &&
                  showMaxSalesFields &&
                  showACOSFields
                ),
              })}
            >
              <Label sm="3">
                ROAS
                <Button
                  color="link"
                  id="goals-roas-label-tooltip"
                  title=""
                  type="button"
                  className={`p-0 m-0 mt-n1 pl-2 ${
                    lightMode ? 'text-muted' : ''
                  }`}
                >
                  <i className="fas fa-question-circle"></i>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="goals-roas-label-tooltip"
                >
                  Return on Advertising Spend (ROAS) is the ratio of attributed
                  sales to advertising spend i.e. the inverse of ACOS
                </UncontrolledTooltip>
              </Label>
              <Col sm="9" md="6">
                <FormGroup>
                  <Input
                    disabled
                    className="form-input-as-display"
                    name="target_roas"
                    type="text"
                    value={Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(targetROAS)}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row
              className={classNames({
                'd-none': !(
                  showCustomFields &&
                  showMaxSalesFields &&
                  showTACOSFields
                ),
              })}
            >
              <Label sm="3" className="">
                {lightMode ? (
                  <b>Target TACOS %</b>
                ) : (
                  <b className="text-white">Target TACOS %</b>
                )}
                <Button
                  color="link"
                  id="goals-tacos-label-tooltip"
                  title=""
                  type="button"
                  className={`p-0 m-0 mt-n1 pl-2 ${
                    lightMode ? 'text-muted' : ''
                  }`}
                >
                  <i className="fas fa-question-circle"></i>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="goals-tacos-label-tooltip"
                >
                  Set your Total Advertising Cost of Sale (TACOS) target,
                  defined as the percent of total sales spent on advertising
                </UncontrolledTooltip>
              </Label>
              <Col sm="9" md="6">
                <FormGroup className={targetTACOSValidation}>
                  <Input
                    name="tacos_target"
                    type="number"
                    min={minTACOS}
                    max="1000"
                    step="0.01"
                    value={targetTACOS}
                    placeholder={TACOSPlaceholder}
                    onChange={(e) => {
                      inputFieldChange(
                        e,
                        setTargetTACOS,
                        setTargetTACOSValidation,
                        'positiveNumberGTE',
                        minTACOS
                      )
                    }}
                  />
                  {targetTACOSValidation === 'has-danger' ? (
                    <label className="error">
                      This field is required and must be greater than {minTACOS}
                    </label>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>

            <Row
              className={classNames({
                'd-none': !(
                  showCustomFields &&
                  showMaxSalesFields &&
                  showTACOSFields
                ),
              })}
            >
              <Label sm="3">
                TROAS
                <Button
                  color="link"
                  id="goals-troas-label-tooltip"
                  title=""
                  type="button"
                  className={`p-0 m-0 mt-n1 pl-2 ${
                    lightMode ? 'text-muted' : ''
                  }`}
                >
                  <i className="fas fa-question-circle"></i>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="goals-troas-label-tooltip"
                >
                  Total Return on Advertising Spend (TROAS) is the ratio of
                  total sales to advertising spend i.e. the inverse of TACOS
                </UncontrolledTooltip>
              </Label>
              <Col sm="9" md="6">
                <FormGroup>
                  <Input
                    disabled
                    className="form-input-as-display"
                    name="target_troas"
                    type="text"
                    value={Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(targetTROAS)}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row
              className={classNames('mb-3', {
                'd-none': !(
                  showCustomFields &&
                  showMaxSalesFields &&
                  showProfitFields
                ),
              })}
            >
              <Label sm="3" className="">
                Priority
                <Button
                  color="link"
                  id="goals-priority-label-tooltip"
                  title=""
                  type="button"
                  className={`p-0 m-0 mt-n1 pl-2 ${
                    lightMode ? 'text-muted' : ''
                  }`}
                >
                  <i className="fas fa-question-circle"></i>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="goals-priority-label-tooltip"
                >
                  Priority details.
                </UncontrolledTooltip>
              </Label>
              <Col
                lg="6"
                md="6"
                sm="9"
                className={classNames('sliderCol', {
                  'has-warning': priorityValidation,
                })}
              >
                <div className="slider" ref={prioritySliderRef} />
                {priorityValidation === 'has-danger' ? (
                  <label className="error text-warning pt-4 mb-0">
                    We recommend prioritizing more sales.
                  </label>
                ) : null}
              </Col>
            </Row>

            <Row
              className={classNames('', {
                'd-none': !(
                  showCustomFields &&
                  showMaxSalesFields &&
                  showProfitFields
                ),
              })}
            >
              <Label sm="3">
                {type === 'global' ? 'Avg.' : ''} Unit Ad Spend
                <Button
                  color="link"
                  id="goals-ad-spend-label-tooltip"
                  title=""
                  type="button"
                  className={classNames('p-0 m-0 mt-n1 pl-2', {
                    'text-muted': lightMode,
                  })}
                >
                  <i className="fas fa-question-circle"></i>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="goals-ad-spend-label-tooltip"
                >
                  Avg. Unit Ad Spend details.
                </UncontrolledTooltip>
              </Label>
              <Col sm="9" md="6">
                <FormGroup>
                  <Input
                    disabled
                    className={classNames('form-input-as-display', {
                      'text-warning': priorityValidation,
                    })}
                    name="product_ad_spend"
                    type="text"
                    value={
                      Intl.NumberFormat('en-US', {
                        style: 'percent',
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      }).format(avgProductAdSpendPerc) +
                      '     ' +
                      (type === 'global' ? '(~' : '(') +
                      Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: currency,
                      }).format(avgProductAdSpend) +
                      ')'
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row
              className={classNames('mb-3', {
                'd-none': !(
                  showCustomFields &&
                  showMaxSalesFields &&
                  showProfitFields
                ),
              })}
            >
              <Label sm="3">
                <b className="text-white">
                  Target {type === 'global' ? 'Avg.' : ''} Unit Net Profit
                </b>
                <Button
                  color="link"
                  id="goals-product-profit-label-tooltip"
                  title=""
                  type="button"
                  className={classNames('p-0 m-0 mt-n1 pl-2')}
                >
                  <i className="fas fa-question-circle"></i>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="goals-product-profit-label-tooltip"
                >
                  Target Avg. Unit Net Profit details.
                </UncontrolledTooltip>
              </Label>
              <Col sm="9" md="6">
                <FormGroup>
                  <Input
                    disabled
                    className={classNames('form-input-as-display', {
                      'text-danger': negativeProfitValidation,
                    })}
                    name="product_profit"
                    type="text"
                    value={
                      Intl.NumberFormat('en-US', {
                        style: 'percent',
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      }).format(avgProductMargin) +
                      '     ' +
                      (type === 'global' ? '(~' : '(') +
                      Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: currency,
                      }).format(avgProductProfit) +
                      ')'
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row
              className={classNames({
                'd-none': !(
                  showCustomFields &&
                  showMaxSalesFields &&
                  showBudgetFields
                ),
              })}
            >
              <Label sm="3" className="">
                <b className="text-white">Target Daily Budget</b>
                <Button
                  color="link"
                  id="goals-budget-label-tooltip"
                  title=""
                  type="button"
                  className={`p-0 m-0 mt-n1 pl-2 ${
                    lightMode ? 'text-muted' : ''
                  }`}
                >
                  <i className="fas fa-question-circle"></i>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="goals-budget-label-tooltip"
                >
                  Target Daily Budget details.
                </UncontrolledTooltip>
              </Label>
              <Col sm="9" md="6">
                <FormGroup className={targetBudgetValidation}>
                  <Input
                    name="shipping_cost"
                    type="number"
                    min={minBudget}
                    max="1000"
                    step="0.01"
                    value={targetBudget}
                    placeholder={budgetPlaceholder}
                    onChange={(e) => {
                      inputFieldChange(
                        e,
                        setTargetBudget,
                        setTargetBudgetValidation,
                        'positiveNumberGTE',
                        minBudget
                      )
                    }}
                  />
                  {targetBudgetValidation === 'has-danger' ? (
                    <label className="error">
                      This field is required and must be greater than{' '}
                      {Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: currency,
                      }).format(minBudget)}
                    </label>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>

            {useCampaignTypes && (
              <Row className={classNames('mb-3')}>
                <Label sm="3" className="">
                  Campaign Types
                </Label>
                <Col sm="9" md="6">
                  <InputGroup>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      name="campaign_types"
                      value={campaignTypes[0]}
                      placeholder=""
                      onChange={(e) => {
                        setCampaignTypes([{ value: e.value, label: e.label }])
                      }}
                      // onFocus={e => setStrategyFocus(true)}
                      // onBlur={e => setStrategyFocus(false)}
                      options={campaignTypeOptions}
                    />
                  </InputGroup>
                </Col>
              </Row>
            )}

            <Row
              className={classNames({
                'd-none': !showCustomFields,
              })}
            >
              <Label sm="3">
                Turbo Mode
                <Button
                  color="link"
                  id="goals-autron-turbomode-label-tooltip"
                  title=""
                  type="button"
                  className={`p-0 m-0 mt-n1 pl-2 ${
                    lightMode ? 'text-muted' : ''
                  }`}
                >
                  <i className="fas fa-question-circle"></i>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="goals-autron-turbomode-label-tooltip"
                >
                  When Turbo Mode is ON, Autron aggressively bids to establish
                  impressions and clicks for every keyword or product target
                  within its campaigns. Ideal for product launches or boosting
                  visibility, this mode prioritizes these metrics over
                  ACOS/TACOS targets
                </UncontrolledTooltip>
              </Label>
              <Col sm="9" md="6">
                <Switch
                  value={turboOn}
                  offColor=""
                  //offText="off"
                  onColor=""
                  //onText="on"
                  onChange={() => toogleTurboOn()}
                />
              </Col>
            </Row>

            <Row className="mt-5">
              <Label sm="3"></Label>
              <Col sm="9" md="6">
                <Button
                  color="primary"
                  className={classNames('btn-wd', { disabled: loading })}
                  onClick={(e) => saveClick(e)}
                >
                  {loading === true ? (
                    <Spinner size="sm" role="status"></Spinner>
                  ) : goalGlobalPublicId ? (
                    adsOn ? (
                      'Save Goal'
                    ) : (
                      'Relaunch Autron!'
                    )
                  ) : (
                    'Launch Autron!'
                  )}
                </Button>
                {adsOn ? (
                  <Button
                    color={type !== 'global' ? 'warning' : 'danger'}
                    onClick={(e) => {
                      type === 'global' ? showAlertModal() : disableGoalState(e)
                    }}
                  >
                    {loadingDisable === true ? (
                      <Spinner size="sm" role="status"></Spinner>
                    ) : type === 'global' ? (
                      'Disable Autron'
                    ) : (
                      'Disable Goal'
                    )}
                  </Button>
                ) : null}

                {type !== 'global' ? (
                  <Button
                    color="primary"
                    className={classNames('btn-simple')}
                    onClick={(e) => history.push('/admin/goals')}
                  >
                    Cancel
                  </Button>
                ) : null}
              </Col>
            </Row>
          </Form>
        </Fragment>
      )}
    </>
  )
}

export default Goal
