import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import classnames from 'classnames'
// reactstrap components
import {
  Spinner,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  NavLink,
} from 'reactstrap'

import apiUsers from 'services/api/User'
import apiAuth from 'services/api/Auth'
import { setAuthToken } from 'variables/authToken'

import { inputFieldChange } from 'services/Utils'
import useAlert from 'services/hooks/useAlert'
import cardPrimaryImg from 'assets/img/card-primary.png'

import Select from 'react-select'

const Register = () => {
  const params = new URLSearchParams(useLocation().search)

  const [firstname, setFirstname] = useState(params.get('firstname') || '')
  const [lastname, setLastname] = useState(params.get('lastname') || '')
  const [company, setCompany] = useState(params.get('company') || '')
  const [email, setEmail] = useState(params.get('email') || '')
  const [password, setPassword] = useState('')
  const [, setTerms] = useState('')
  const [, setExternalManagement] = useState('')

  const [hearFrom, setHearFrom] = useState('')
  const [reasonToUse, setReasonToUse] = useState('')

  const [firstnameFocus, setFirstnameFocus] = useState(false)
  const [lastnameFocus, setLastnameFocus] = useState(false)
  const [companyFocus, setCompanyFocus] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)
  const [passwordFocus, setPasswordFocus] = useState(false)
  const [hearFromFocus, setHearFromFocus] = useState(false)
  const [reasonToUseFocus, setReasonToUseFocus] = useState(false)

  const [firstnameValidationState, setFirstnameValidationState] = useState(
    params.get('firstname') ? 'has-success' : ''
  )
  const [lastnameValidationState, setLastnameValidationState] = useState(
    params.get('lastname') ? 'has-success' : ''
  )
  const [companyValidationState, setCompanyValidationState] = useState(
    params.get('company') ? 'has-success' : ''
  )
  const [emailValidationState, setEmailValidationState] = useState(
    params.get('email') ? 'has-success' : ''
  )
  const [passwordValidationState, setPasswordValidationState] = useState('')
  const [termsValidationState, setTermsValidationState] = useState('')
  const [
    externalManagementValidationState,
    setExternalManagementValidationState,
  ] = useState('')

  const [hearFromValidationState, setHearFromValidationState] = useState('')
  const [reasonToUseValidationState, setReasonToUseValidationState] =
    useState('')

  const [loading, setLoading] = useState(false)
  const { addAlert } = useAlert()
  const history = useHistory()

  const [managingCompanyPublicId] = useState(params.get('managing_company'))
  const [managingCompanyName] = useState(params.get('managing_company_name'))

  // Options for the select inputs
  const hearFromOptions = [
    { value: 'Introduced', label: 'I was introduced to Autron' },
    { value: 'Autron outreach', label: 'Autron outreach' },
    { value: 'Google', label: 'Google' },
    { value: 'YouTube', label: 'YouTube' },
    { value: 'Facebook', label: 'Facebook' },
    { value: 'LinkedIn', label: 'LinkedIn' },
    { value: 'Reddit', label: 'Reddit' },
    { value: 'Blogs', label: 'Blogs' },
    { value: 'Influencers', label: 'Influencers' },
  ]

  const reasonToUseOptions = [
    {
      value: '24/7 AI campaign optimization',
      label: '24/7 AI campaign optimization',
    },
    {
      value: 'Pay for ad sales, not ad spend',
      label: 'Pay for ad sales, not ad spend',
    },
    { value: 'Save time', label: 'Save time' },
    { value: 'Save money', label: 'Save money' },
    { value: 'Free PPC audit', label: 'Free PPC audit' },
  ]

  const handleHearFromChange = (selectedOption) => {
    inputFieldChange(
      { target: { value: selectedOption.value } },
      setHearFrom,
      setHearFromValidationState,
      'length',
      1
    )
  }

  const handleReasonToUseChange = (selectedOption) => {
    inputFieldChange(
      { target: { value: selectedOption.value } },
      setReasonToUse,
      setReasonToUseValidationState,
      'length',
      1
    )
  }

  // Post login data to API if everything is validated
  const loginUser = async () => {
    if (
      emailValidationState === 'has-success' &&
      passwordValidationState === 'has-success'
    ) {
      const auth = { email, password }
      // Login via API
      const response = await apiAuth
        .login(JSON.stringify(auth))
        .then((res) => res)

      if (response?.status === 200) {
        setAuthToken(response.data.Authorization)
        history.push('/admin')
      } else {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          response?.data?.message,
          null
        )
      }
    }
  }

  // post user data to api if everything validated
  const registerClick = async (e) => {
    if (
      firstnameValidationState === 'has-success' &&
      lastnameValidationState === 'has-success' &&
      companyValidationState === 'has-success' &&
      emailValidationState === 'has-success' &&
      passwordValidationState === 'has-success' &&
      termsValidationState === 'has-success' &&
      reasonToUseValidationState === 'has-success' &&
      hearFromValidationState === 'has-success' &&
      (externalManagementValidationState === 'has-success' ||
        !managingCompanyPublicId)
    ) {
      setLoading(true)

      const managing_company_public_id = managingCompanyPublicId
      const hear_from = hearFrom
      const reason_to_use = reasonToUse

      const user = {
        firstname,
        lastname,
        company,
        email,
        password,
        managing_company_public_id,
        hear_from,
        reason_to_use,
      }

      // create user via api
      const response = await apiUsers
        .createSingle(JSON.stringify(user))
        .then((res) => {
          return res
        })

      if (response && response.status < 300) {
        addAlert(
          'success',
          'tim-icons icon-check-2',
          response?.data?.message,
          null
        )

        // login user after successfull registration
        loginUser()
      } else {
        if (response) {
          addAlert(
            'danger',
            'tim-icons icon-alert-circle-exc',
            response?.data?.message,
            null
          )
        }
        e.preventDefault()
      }
      setLoading(false)
    } else {
      if (firstnameValidationState === '') {
        setFirstnameValidationState('has-danger')
      }
      if (lastnameValidationState === '') {
        setLastnameValidationState('has-danger')
      }
      if (companyValidationState === '') {
        setCompanyValidationState('has-danger')
      }
      if (emailValidationState === '') {
        setEmailValidationState('has-danger')
      }
      if (passwordValidationState === '') {
        setPasswordValidationState('has-danger')
      }
      if (termsValidationState === '') {
        setTermsValidationState('has-danger')
      }
      if (externalManagementValidationState === '') {
        setExternalManagementValidationState('has-danger')
      }
      if (hearFromValidationState === '') {
        setHearFromValidationState('has-danger')
      }
      if (reasonToUseValidationState === '') {
        setReasonToUseValidationState('has-danger')
      }
      e.preventDefault()
    }
  }

  useEffect(() => {
    document.body.classList.toggle('register-page')
    return () => {
      document.body.classList.toggle('register-page')
    }
  }, [])

  return (
    <>
      <div className="content">
        <Container>
          <Row>
            <Col className="ml-auto" md="5">
              <div className="info-area info-horizontal mt-5">
                <div className="icon icon-warning">
                  <i className="tim-icons icon-atom" />
                </div>
                <div className="description">
                  <h3 className="info-title">24/7 AI campaign optimization</h3>
                  <p className="description">
                    Autron analyzes and optimizes your ads 24/7, using
                    dayparting, weekly trends, and seasonality to keep your
                    campaigns effective.
                  </p>
                </div>
              </div>
              <div className="info-area info-horizontal">
                <div className="icon icon-primary">
                  <i className="tim-icons icon-refresh-02" />
                </div>
                <div className="description">
                  <h3 className="info-title">Pay for ad sales, not ad spend</h3>
                  <p className="description">
                    Gone are the days of agencies taking your money by spending
                    more on your behalf. With Autron, you only pay a commission
                    on actual Autron Ad Sales.
                  </p>
                </div>
              </div>
              <div className="info-area info-horizontal">
                <div className="icon icon-info">
                  <i className="tim-icons icon-spaceship" />
                </div>
                <div className="description">
                  <h3 className="info-title">Save time & money</h3>
                  <p className="description">
                    Leave behind endless spreadsheets, CPCs, CTRs, CVRs, and the
                    constant analysis of ACOS. Focus on building your business
                    and nurturing relationships while Autron handles the heavy
                    lifting for you.
                  </p>
                </div>
              </div>
            </Col>
            <Col className="mr-auto" md="7">
              <Card className="card-register card-white overflow-visible">
                <CardHeader className="pb-0">
                  <CardImg
                    className="position-relative"
                    alt="..."
                    src={cardPrimaryImg}
                    style={{
                      marginTop: '-100px',
                    }}
                  />
                  <CardTitle className="position-absolute top-0" tag="h4">
                    Register
                  </CardTitle>
                </CardHeader>
                <CardBody
                  style={{
                    marginTop: '-50px',
                  }}
                >
                  <Form className="form form-auth">
                    <InputGroup
                      className={classnames(firstnameValidationState, {
                        'input-group-focus': firstnameFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="First Name"
                        type="text"
                        autoComplete="given-name"
                        onFocus={(e) => setFirstnameFocus(true)}
                        onBlur={(e) => setFirstnameFocus(false)}
                        onChange={(e) =>
                          inputFieldChange(
                            e,
                            setFirstname,
                            setFirstnameValidationState,
                            'length',
                            1
                          )
                        }
                        defaultValue={firstname}
                      />
                      {firstnameValidationState === 'has-danger' ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                    <InputGroup
                      className={classnames(lastnameValidationState, {
                        'input-group-focus': lastnameFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-caps-small" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Last Name"
                        type="text"
                        autoComplete="family-name"
                        onFocus={(e) => setLastnameFocus(true)}
                        onBlur={(e) => setLastnameFocus(false)}
                        onChange={(e) =>
                          inputFieldChange(
                            e,
                            setLastname,
                            setLastnameValidationState,
                            'length',
                            1
                          )
                        }
                        defaultValue={lastname}
                      />
                      {lastnameValidationState === 'has-danger' ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                    <InputGroup
                      className={classnames(companyValidationState, {
                        'input-group-focus': companyFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-badge" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Company"
                        type="text"
                        autoComplete="organization"
                        onFocus={(e) => setCompanyFocus(true)}
                        onBlur={(e) => setCompanyFocus(false)}
                        onChange={(e) =>
                          inputFieldChange(
                            e,
                            setCompany,
                            setCompanyValidationState,
                            'length',
                            1
                          )
                        }
                        defaultValue={company}
                      />
                      {companyValidationState === 'has-danger' ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                    <InputGroup
                      className={classnames(emailValidationState, {
                        'input-group-focus': emailFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="text"
                        autoComplete="email"
                        onFocus={(e) => setEmailFocus(true)}
                        onBlur={(e) => setEmailFocus(false)}
                        onChange={(e) =>
                          inputFieldChange(
                            e,
                            setEmail,
                            setEmailValidationState,
                            'email'
                          )
                        }
                        defaultValue={email}
                      />
                      {emailValidationState === 'has-danger' ? (
                        <label className="error">
                          Please enter a valid email address.
                        </label>
                      ) : null}
                    </InputGroup>
                    <InputGroup
                      className={classnames(passwordValidationState, {
                        'input-group-focus': passwordFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-lock-circle" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        autoComplete="new-password"
                        onFocus={(e) => setPasswordFocus(true)}
                        onBlur={(e) => setPasswordFocus(false)}
                        onChange={(e) =>
                          inputFieldChange(
                            e,
                            setPassword,
                            setPasswordValidationState,
                            'password',
                            10
                          )
                        }
                      />
                      {passwordValidationState === 'has-danger' ? (
                        <label className="error">
                          Please ensure password is at least 10 characters and
                          non-numeric.
                        </label>
                      ) : null}
                    </InputGroup>

                    <InputGroup
                      className={classnames('mb-0', hearFromValidationState, {
                        'input-group-focus': hearFromFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-chat-33" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Select
                        name="hearFrom"
                        className="react-select info register-input"
                        classNamePrefix="react-select"
                        placeholder="How did you hear of Autron?"
                        defaultValue={hearFrom}
                        options={hearFromOptions}
                        onChange={handleHearFromChange}
                        onFocus={(e) => setHearFromFocus(true)}
                        onBlur={(e) => setHearFromFocus(false)}
                      />
                    </InputGroup>
                    {hearFromValidationState === 'has-danger' && (
                      <label className="text-danger">
                        This field is required.
                      </label>
                    )}

                    <InputGroup
                      className={classnames(
                        'mb-0 mt-2',
                        reasonToUseValidationState,
                        {
                          'input-group-focus': reasonToUseFocus,
                        }
                      )}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-bulb-63" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Select
                        name="reasonToUse"
                        className="react-select info register-input"
                        classNamePrefix="react-select"
                        placeholder="Why do you want to use Autron?"
                        defaultValue={reasonToUse}
                        options={reasonToUseOptions}
                        onChange={handleReasonToUseChange}
                        onFocus={() => setReasonToUseFocus(true)}
                        onBlur={() => setReasonToUseFocus(false)}
                      />
                    </InputGroup>
                    {reasonToUseValidationState === 'has-danger' && (
                      <label className="text-danger">
                        This field is required.
                      </label>
                    )}

                    {managingCompanyPublicId ? (
                      <FormGroup
                        check
                        className={classnames(
                          externalManagementValidationState,
                          'text-left'
                        )}
                      >
                        <Label check>
                          <Input
                            type="checkbox"
                            onChange={(e) =>
                              inputFieldChange(
                                e,
                                setExternalManagement,
                                setExternalManagementValidationState,
                                'checkbox'
                              )
                            }
                          />
                          <span className="form-check-sign" />I agree to{' '}
                          <span className="font-weight-bold">
                            {managingCompanyName}
                          </span>{' '}
                          managing Autron on my behalf.
                          {externalManagementValidationState ===
                          'has-danger' ? (
                            <label className="error">
                              Please agree to external account management.
                            </label>
                          ) : null}
                        </Label>
                      </FormGroup>
                    ) : null}
                    <FormGroup
                      check
                      className={classnames(termsValidationState, 'text-left')}
                    >
                      <Label check>
                        <Input
                          type="checkbox"
                          onChange={(e) =>
                            inputFieldChange(
                              e,
                              setTerms,
                              setTermsValidationState,
                              'checkbox'
                            )
                          }
                        />
                        <span className="form-check-sign" />I agree to the{' '}
                        <NavLink
                          href={`${process.env.REACT_APP_FRONTEND_APP_BASE_URL}/terms`}
                          className="text-primary d-inline p-0"
                        >
                          terms of service.
                        </NavLink>
                        {termsValidationState === 'has-danger' ? (
                          <label className="error">
                            Please agree to the terms of service.
                          </label>
                        ) : null}
                      </Label>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter className="m-0">
                  <Button
                    className={classnames('btn-round btn-wd', {
                      disabled: loading,
                    })}
                    color="primary"
                    onClick={(e) => registerClick(e)}
                    size="md"
                  >
                    {loading === true ? (
                      <Spinner size="sm" role="status"></Spinner>
                    ) : (
                      'Register'
                    )}
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Register
