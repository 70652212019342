import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  Spinner,
  Col,
} from 'reactstrap'

import CheckoutForm from './CheckoutForm'

import apiAuth from 'services/api/Auth'
import { filterByStatuses } from 'services/Utils'
const Payment = () => {
  const [user, setUser] = useState(null)

  useQuery('GET/auth/user', () => apiAuth.user(), {
    onSuccess: (data) => {
      setUser(data)
    },
  })

  return (
    <>
      <Card className="card-lock card-white mt-5 mt-lg-0">
        <div className="p-3">
          <CardHeader>
            <CardTitle tag="h2" className="payment-card-title">
              Ready to turbocharge your Amazon Advertising?
            </CardTitle>
          </CardHeader>
          <CardBody>
            {user ? (
              <>
                {filterByStatuses(user?.company?.stripe_subscription_items, [
                  'canceled',
                ]) !== null ? (
                  <CardSubtitle tag="p" className="description mb-2">
                    Before we continue, we'll need to update your payment
                    details to resume your Pro subscription. A payment is due
                    today, and you'll be billed monthly based on your usage.
                    We'll notify you a few days before each billing cycle.
                  </CardSubtitle>
                ) : (
                  <CardSubtitle tag="p" className="description mb-2">
                    Before we begin, we'll need your card for billing purposes.
                    You won't be charged during the trial, and we'll send you a
                    reminder a few days before the trial ends about the upcoming
                    renewal date.
                  </CardSubtitle>
                )}
                <CheckoutForm user={user} />
              </>
            ) : (
              <Col className="text-center mt-5 mt-lg-0">
                <Spinner color="primary" />
              </Col>
            )}
          </CardBody>
        </div>
      </Card>
    </>
  )
}

export default Payment
