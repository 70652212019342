import axios from 'axios'
import { handleResponse, handleError } from './utilities/Response'
import { ApiCore } from './utilities/Core'
import { setAuthorizationHeader } from './utilities/Provider'

// Review Automation API
const url = 'review-automation'

const apiReviewAutomation = new ApiCore({
  createSingle: true,
  url: url,
})

apiReviewAutomation.setState = async (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return await axios
    .post(`/${url}/`, data, {
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiReviewAutomation.getState = async (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return await axios
    .get(`/${url}/status`, {
      withCredentials: withCredentials,
      params: data,
    })
    .then(handleResponse)
    .catch(handleError)
}

export default apiReviewAutomation
